<template lang="pug">
.robots
  h1 Robôs Partipantes

  div(
    v-for="(category, indexCategories) in takeCategories"
    :key="category.value"
  )
    .robots__category(
      v-if="takeCategories.length"
      @click="doShowCategory(indexCategories)"
    )
      h2 {{takeCategories[indexCategories].name}}

      FontAwesomeIcon.icon(
        :class="takeShowCategories[indexCategories] ? 'icon--inverted' : ''"
        :icon="['fas', 'chevron-up']"
      )

    .robots__list(
      v-if="takeShowCategories[indexCategories]"
    )
      Icon.robot(
        v-for="(robot, indexRobot) in takeRobots[indexCategories]"
        :key="indexRobot"
        :file="robot.image"
        type="base64"
        :icon="['fas', 'robot']"
        :texts="robot.texts"
        @click4='doNavigateToInspectRobot(category.value, indexRobot)'
      )

</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  Icon
} from '@/components/atoms'

export default {
  name: 'Robots',
  props: {},
  data: () => ({
    showCategories: []
  }),
  created () {
    this.setRobots()
    this.setCategories()
    this.doSetShowCategoriesFalse()
  },
  components: {
    Icon
  },
  computed: {
    ...mapGetters('robots', [
      'getRobots'
    ]),
    ...mapGetters('categories', [
      'getCategories'
    ]),
    ...mapGetters('users', [
      'getUser'
    ]),
    takeRobots () {
      const arr = this.takeCategories.map(category => {
        return this.getRobots.filter(robot => robot.category_id === category.value).map(robot => {
          const ret = { image: robot.image, texts: [{ text: robot.name }, { text: robot.team_name }] }
          if (this.getUser.roles.includes(2)) {
            ret.texts = [...ret.texts, { text: 'Dinâmica:', icon: robot.dynamic_inspection ? ['fas', 'check'] : ['fas', 'times'] }, { text: 'Estática:', icon: robot.static_inspection ? ['fas', 'check'] : ['fas', 'times'] }, { text: 'Inspeção', click: true }]
          }
          return ret
        })
      })
      return arr
    },
    takeCategories () {
      let ret = []
      if (this.getCategories.length) {
        ret = [...this.getCategories].map(category => {
          return { name: category.name, value: category.id }
        })
      }
      return ret
    },
    takeShowCategories () {
      return this.showCategories
    }
  },
  methods: {
    ...mapActions('robots', [
      'setRobots',
      'setCurrentRobot'
    ]),
    ...mapActions('categories', [
      'setCategories'
    ]),
    doSetShowCategoriesFalse () {
      for (let i = 0; i < 6; i++) {
        this.showCategories[i] = false
      }
    },
    doNavigateToInspectRobot (category, index) {
      this.setCurrentRobot(this.getRobots.filter(robot => robot.category_id === category)[index])
      this.$router.push('InspectRobot')
    },
    doShowCategory (e) {
      const robots = [...this.showCategories]
      robots[e] = !this.showCategories[e]
      this.showCategories = [...robots]
    }
  }
}
</script>

<style lang="sass" scoped>
  .robots
    &__category
      border-bottom: 2px solid #9d9d9d
      display: flex
      align-items: center
      cursor: pointer
      .icon
        margin: 8px
        &--inverted
          transform: rotate(180deg)

    &__list
      display: flex
      justify-content: flex-start
      align-items: center
      flex-wrap:  wrap
      .robot
        margin: 24px
</style>
